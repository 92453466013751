import React from "react";
import { Text, Image, RichText, Link } from "@sitecore-jss/sitecore-jss-react";
import NavLinkAdv from "../../utils/NavLinkAdv";
import { getRelativePaths } from "../../utils/Utils.js";
import "./FeaturedPosts.scss";
const FeaturedPosts = props => {
  
  const { FeaturedPostsContentList, designVariant } = props.fields;
  return (
    <div className={`${designVariant && designVariant.value}`}>
      {props.fields.Title &&
        props.fields.Title.value !== "" &&
        props.fields.Text &&
        props.fields.Text.value !== "" && (
          <div className="row featured_posts_custom_heading">
            <div className="col-md-12">
              <Text field={props.fields.Title} tag="h1" />
              <blockquote>
                <Text field={props.fields.Text} tag="p" />
              </blockquote>
            </div>
          </div>
        )}

      <div className="row box-1 featured_posts_custom">
        {FeaturedPostsContentList &&
          FeaturedPostsContentList.map((listItem, index) => (
            <div className="col-sm-6 col-md-4 home-gamme__content-wrapper" key={index}>
                <Text
                    field={listItem.fields.title}
                    tag="h3"
                    className="text-white titleGam"
                />
              <div className="home-gamme__inner">
                  <div className="desc-cn">
                      <RichText
                          field={listItem.fields.content}
                      />
                  </div>
                <Image field={listItem.fields.Icon} />
              </div>
                <NavLinkAdv
                    to={getRelativePaths(listItem.fields.PostLink.value.href)}
                    className="btn btn_oro">
                    {listItem.fields.PostLink.value.text}
                </NavLinkAdv>
            </div>
          ))}
      </div>
    </div>
  );
};
export default FeaturedPosts;
