import React from 'react';
import {Image, Text, withSitecoreContext} from '@sitecore-jss/sitecore-jss-react';
import NavLinkAdv from "../../utils/NavLinkAdv";
import GraphQLData from '../../lib/GraphQLData';
import $ from 'jquery/dist/jquery';
import base64 from 'react-native-base64';
import {getRelativePaths} from '../../utils/Utils.js';
import './HorizontalMenu.scss';
import { handleNavigationClick, handleLogoClick } from '../Tagging';

import ReactComponent from "../../ReactComponent";
import { loader as gqlLoader } from "graphql.macro";
import { Query } from "react-apollo";
import {DotLoader} from "react-spinners";
import {css} from "@emotion/core";


const override = css`
  margin: auto;
`;

const RootPathQuery = gqlLoader('./query.graphql');
const HorizontalMenuQuery = gqlLoader('./HorizontalMenu.graphql');

let loadCount = 0;
class HorizontalMenu extends ReactComponent {
    constructor(props) {

        super(props);
        this.state = {
            value: '',
            isMounted: false,
            itemsForRootpath: []
        };
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);

    }

    componentDidMount() {

        this.superfishInit();
        $(window).on("resize", this.superfishInit);
        $(window).scroll(function() {
            //sticky navbar
            var e = window,
                a = "inner";
            if (!("innerWidth" in window)) {
                a = "client";
                e = document.documentElement || document.body;
            }
            var width = e[a + "Width"];
            if (width > 319) {
                if ($(this).scrollTop() > 100) {
                    $(".navbar-default").addClass("navbar-fixed-top");
                    $(".navbar-default").addClass("fixed-transition");
                    $(".header-container").addClass("header-navbar-fixed");
                } else {
                    $(".navbar-default").removeClass("navbar-fixed-top");
                    $(".navbar-default").removeClass("fixed-transition");
                    $(".header-container").removeClass("header-navbar-fixed");
                }
            }
        });

        $(document).on("click", ".navbar-search-button", function(e) {
            e.stopPropagation();
            e.preventDefault();
            if ($(".header-search-overlay").length) {
                $(".header-search-overlay").stop(true, true).removeClass("hide").css("opacity", 0).animate({
                    "opacity": 1
                }, 600, "swing", function() {
                    $(this).find(".searchinput").focus();
                });
            } else if ($(".search-form-wrap").length) {
                if ($(".search-form-wrap").hasClass("hide")) {
                    $(".search-form-wrap").removeClass("hide").addClass("show");
                    $(".search-form-wrap .searchinput").focus();
                }
            }
        });

        $(document).on("click", ".header-search-overlay .close", function() {
            $(".header-search-overlay").stop(true, true).animate({
                "opacity": 0
            }, 600, "swing", function() {
                $(this).addClass("hide");
            });
        });

        this.setState({
            isMounted: true
        });


        //Off Canvas menu
        // $(document).off('click').on("click", ".navbar-toggle, .navbar-offcanvas-btn", function(e) {
        //   this.toggleMobileMenu(e, true)
        // });

        // $(".offcanvas-nav").off("click").on("click", "a.closemenu", function(e){
        //   this.toggleMobileMenu(e,false)
        // })


        $("body").on("mousedown", $.proxy(function(e) {
            var element = $(e.target);
            if ($(".offcanvas").length && $("body").hasClass("open-offcanvas")) {
                if (!element.is(".offcanvas") && element.parents(".offcanvas").length === 0 && !element.is(".navbar-toggle") && element.parents(".navbar-toggle").length === 0 && !element.is(".navbar-offcanvas-btn") && element.parents(".navbar-offcanvas-btn").length === 0) {
                    $("body").removeClass("open-offcanvas");
                    $(".navbar-toggle").removeClass("x");
                }
            }
        }, this));

        $("body").off("click").on("click", ".offcanvas-nav .dropdown-hover .caret", function(e) {
            e.stopPropagation();
            e.preventDefault();
            var dropdown = $(this).closest(".dropdown, .dropdown-submenu");
            if (dropdown.hasClass("open")) {
                dropdown.removeClass("open");
            } else {
                dropdown.addClass("open");
            }
        });
        function ScrollToAnchor(){
            const url = window.location.href;
            if ( url.includes('#')) {

                let hashValue = url.substring(url.indexOf("#") + 1);
                var element = document.getElementById(hashValue)
                if(element){
                    var headerOffset = 100;
                    var elementPosition = element.getBoundingClientRect().top;
                    var offsetPosition = elementPosition - headerOffset;

                    window.scrollTo({
                        top: offsetPosition,
                        behavior: "smooth"
                    });
                }

            }
            return null;
        }

        ScrollToAnchor();


    }

    toggleMobileMenu(e, stop){

        if(stop){
            e.stopPropagation();
            e.preventDefault();
        }

        if ($("body").hasClass("open-offcanvas")) {
            $("body").removeClass("open-offcanvas").addClass("close-offcanvas");
            $(".navbar-toggle").removeClass("x");
        } else {
            $("body").removeClass("close-offcanvas").addClass("open-offcanvas");
            $(".navbar-toggle").addClass("x");
        }
    }



    superfishInit() {
        if($ !== undefined && window.$.fn.superfish !== undefined){
            $.fn.superfish = window.$.fn.superfish;
            $(".primary-nav").superfish({
                anchorClass: ".dropdown", // selector within menu context to define the submenu element to be revealed
                hoverClass: "open", // the class applied to hovered list items
                pathClass: "overideThisToUse", // the class you have applied to list items that lead to the current page
                pathLevels: 1, // the number of levels of submenus that remain open or are restored using pathClass
                delay: 650, // the delay in milliseconds that the mouse can remain outside a submenu without it closing
                animation: {
                    opacity: "show"
                }, // an object equivalent to first parameter of jQuery’s .animate() method. Used to animate the submenu open
                animationOut: {
                    opacity: "hide"
                }, // an object equivalent to first parameter of jQuery’s .animate() method Used to animate the submenu closed
                speed: "fast", // speed of the opening animation. Equivalent to second parameter of jQuery’s .animate() method
                speedOut: "fast", // speed of the closing animation. Equivalent to second parameter of jQuery’s .animate() method
                cssArrows: true, // set to false if you want to remove the CSS-based arrow triangles
                disableHI: false, // set to true to disable hoverIntent detection
            });
        }
    }

    handleChange(event) {
        this.setState({value: event.target.value});
    }

    handleSubmit(event) {
        window.location.href = "/search?search="+this.state.value;
        // A TESTER:
        // this.props.history.replace({ pathname: `/product/${this.props.product.id}`})
        event.preventDefault();
    }

    handleLogout() {
        sessionStorage.removeItem("loggedUser");

    }

    render(){
        let homeUrl = this.state.itemsForRootpath && this.state.itemsForRootpath.data && this.state.itemsForRootpath.data.item && this.state.itemsForRootpath.data.item.url;

        const graphQLResult = this.props.MenuQuery;
        const { datasource } = graphQLResult;
        let user = null;
        if (datasource && datasource.isLoginEnabled && this.state.isMounted && datasource.isLoginEnabled.value === "1") {
            if (sessionStorage.getItem("loggedUser")) {
                let sessionChecker = sessionStorage.getItem('loggedUser')
                let getSession =   base64.decode(sessionChecker)
                user = getSession;
            }
        }

        let headerClass = "header-container header-type-classic header-scroll-resize";

        const designVariant =  this.props.fields.designVariant === undefined ? "" : this.props.fields.designVariant && this.props.fields.designVariant.value;
        return (

            <div className={`${designVariant}`}>
                <div className="header-offcanvas navbar-offcanvas offcanvas open">
                    {datasource && (
                        <div className="offcanvas-wrap">
                            <div className="navbar-toggle-fixed">
                                <button onClick={e => this.toggleMobileMenu(e,true)} type="button" className="navbar-toggle x">
                                    <span className="sr-only">Toggle navigation</span>
                                    <span className="icon-bar bar-top"></span>
                                    <span className="icon-bar bar-middle"></span>
                                    <span className="icon-bar bar-bottom"></span>
                                </button>
                            </div>
                            <div className="offcanvas-user clearfix">

                                {user && datasource && datasource.isLoginEnabled && datasource.isLoginEnabled.value === "1" ? <div className="primary-nav">{user}&nbsp;&nbsp;</div> : ""}
                                {user && datasource && datasource.isLoginEnabled && datasource.isLoginEnabled.value === "1" ?
                                    (<a className="offcanvas-user-account-link" href="#" onClick={this.handleLogout.bind(this)}>
                                        <i className="fa fa-user"></i> LOGOUT
                                    </a>)
                                    : (datasource && datasource.isLoginEnabled && datasource.isLoginEnabled.value === "1" ?
                                        <NavLinkAdv className="offcanvas-user-account-link" to="/Login"><i className="fa fa-user"></i> LOGIN / REGISTER</NavLinkAdv>
                                        : "")
                                }
                            </div>
                            <nav className="offcanvas-navbar mobile-offcanvas-navbar">
                                <ul className="offcanvas-nav">
                                    {datasource && datasource.children.map((listItem, index) => (
                                        <li key={`sharedListItem-${index}`} className="menu-item-has-children dropdown">

                                            <NavLinkAdv onClick={() => handleNavigationClick("burger menu", listItem.title)} className="dropdown-hover" to={getRelativePaths(listItem.internalLink.url)}>
                                                <span onClick={e => this.toggleMobileMenu(e,false)} className="underline"><Text field={listItem.title} /></span>
                                            </NavLinkAdv>
                                            <div id="dropdownMenuButton">
                                                {listItem.children.length > 0 && <span className="caret"></span>}
                                            </div>
                                            {listItem.children.length > 0 &&
                                            <ul className="dropdown-menu" aria-labelledby='dropdownMenuButton'>
                                                {
                                                    listItem.children.map((subListItem, index) => (
                                                        <li key={`sharedSubListItem-${index}`} className="menu-item">
                                                            <a onClick={e => this.toggleMobileMenu(e,false)} href={getRelativePaths(subListItem.internalLink.url)} >
                                                                <Text field={subListItem.title} />
                                                            </a>
                                                        </li>
                                                    ))}
                                            </ul>
                                            }
                                        </li>
                                    ))
                                    }
                                </ul>
                            </nav>


                            <div className="offcanvas-widget hide-iphone">
                                <div className="widget widget_search">
                                    <h4 className="widget-title"><span>Recherche</span></h4>
                                    <form onSubmit={this.handleSubmit}>
                                        <label htmlFor="s" className="sr-only">Recherche</label>
                                        <input type="search" id="s" name="s" className="form-control" value={this.state.value} placeholder="Rechercher..." onChange={this.handleChange}/>
                                        <input type="submit" id="searchsubmit" className="hidden" name="submit" value="Search"/>
                                    </form>
                                </div>

                            </div>


                        </div>
                    )}
                </div>
                <div className="offcanvas-overlay"></div>
                <header id="header" className={headerClass}>
                    {datasource && (
                        <div className="navbar-container">
                            <div className="navbar navbar-default navbar-scroll-fixed">
                                <div className="navbar-default-container">
                                    <div className="navbar-default-wrap">
                                        <div className="container">
                                            <div className="row">
                                                <div className="navbar-default-col">
                                                    <div className="navbar-wrap">
                                                        <div className="navbar-header">
                                                            <button onClick={e => this.toggleMobileMenu(e,true)} type="button" className="navbar-toggle">
                                                                <span className="sr-only">Toggle navigation</span>
                                                                <span className="icon-bar bar-top"></span>
                                                                <span className="icon-bar bar-middle"></span>
                                                                <span className="icon-bar bar-bottom"></span>
                                                            </button>
                                                            <a className="navbar-search-button search-icon-mobile" href="#">
                                                                <svg xmlSpace="preserve" viewBox="0 0 612 792" y="0px" x="0px" xmlnsXlink="http://www.w3.org/1999/xlink" xmlns="http://www.w3.org/2000/svg" version="1.1">
                                                                    <g>
                                                                        <g>
                                                                            <g>
                                                                                <path d="M231,104c125.912,0,228,102.759,228,229.5c0,53.034-18.029,101.707-48.051,140.568l191.689,192.953
																		c5.566,5.604,8.361,12.928,8.361,20.291c0,7.344-2.795,14.688-8.361,20.291C597.091,713.208,589.798,716,582.5,716
																		s-14.593-2.792-20.139-8.396L370.649,514.632C332.043,544.851,283.687,563,231,563C105.088,563,3,460.241,3,333.5
																		S105.088,104,231,104z M231,505.625c94.295,0,171-77.208,171-172.125s-76.705-172.125-171-172.125
																		c-94.295,0-171,77.208-171,172.125S136.705,505.625,231,505.625z"/>
                                                                            </g>
                                                                        </g>
                                                                    </g>
                                                                </svg>
                                                            </a>
                                                            <Query query={RootPathQuery} variables={{rootItem:'/sitecore/content/' + this.appName + '/home'}} >
                                                                {({ loading, error, data}) => {
                                                                    if (loading)
                                                                        return(
                                                                            <div className='sweet-loading' style={{marginTop:'50px', marginBottom: '50px'}}>
                                                                                <DotLoader
                                                                                    css={override}
                                                                                    sizeUnit={"px"}
                                                                                    size={50}
                                                                                    color={"#e1191c"}
                                                                                    loading={!this.state.isLoaded}
                                                                                />
                                                                            </div>
                                                                        );

                                                                    if (error)
                                                                        return (
                                                                            <div>Error: {error.message}</div>
                                                                        );
                                                                    let homeUrl = data && data.item && data.item.url;
                                                                    return (
                                                                        <NavLinkAdv onClick={() => handleLogoClick()} className="navbar-brand" to={ homeUrl}>
                                                                            {headerClass === "header-container header-type-classic header-absolute header-transparent header-scroll-resize"
                                                                                ? <img src={datasource.sitelogoTransparent && datasource.sitelogoTransparent.src} className="logo" alt="Dop"  />
                                                                                : <img src={datasource.sitelogo && datasource.sitelogo.src} className="logo" alt="Dop"  />
                                                                            }
                                                                            <img src={datasource.sitelogoFixed &&datasource.sitelogoFixed.src} className="logo-fixed" alt="Dop"   />
                                                                            <img src={datasource.sitelogoMobile && datasource.sitelogoMobile.src} className="logo-mobile" alt="Dop"  />
                                                                        </NavLinkAdv>

                                                                    );
                                                                }
                                                                }
                                                            </Query>
                                                        </div>
                                                        <nav className="collapse navbar-collapse primary-navbar-collapse">
                                                            <ul className="nav navbar-nav primary-nav">
                                                                {
                                                                    datasource.children.map((listItem, index) => (
                                                                        <li key={`sharedListItem-${index}`} className="menu-item-has-children dropdown">

                                                                            <NavLinkAdv  className="dropdown-hover" activeClassName={"is-active"} to={getRelativePaths(listItem.internalLink.url)}>
                                                                                <div onClick={() => handleNavigationClick("header", listItem.title)} className="nav__item">
                                                                                    <Text field={listItem.title} />&nbsp;
                                                                                    {/*{listItem.children.length > 0 && <span className="caret"></span>}                                                         */}
                                                                                </div>
                                                                            </NavLinkAdv>
                                                                            {listItem.children.length > 0 &&
                                                                            <ul className="dropdown-menu">
                                                                                {
                                                                                    listItem.children.map((subListItem, index) => (
                                                                                        <li onClick={() => handleNavigationClick("header", listItem.title.value+"::"+subListItem.title.value)} key={`sharedSubListItem-${index}`} className="menu-item">
                                                                                            <NavLinkAdv to={getRelativePaths(subListItem.internalLink.url)} >
                                                                                                <Text field={subListItem.title} />
                                                                                            </NavLinkAdv>
                                                                                        </li>
                                                                                    ))}
                                                                            </ul>
                                                                            }
                                                                        </li>
                                                                    ))
                                                                }
                                                            </ul>
                                                        </nav>
                                                        <div className="header-right">
                                                            <div className="navbar-search">
                                                                <a className="navbar-search-button" href="#">
                                                                    <svg xmlSpace="preserve" viewBox="0 0 612 792" y="0px" x="0px" xmlnsXlink="http://www.w3.org/1999/xlink" xmlns="http://www.w3.org/2000/svg" version="1.1">
                                                                        <g>
                                                                            <g>
                                                                                <g>
                                                                                    <path d="M231,104c125.912,0,228,102.759,228,229.5c0,53.034-18.029,101.707-48.051,140.568l191.689,192.953
                                      c5.566,5.604,8.361,12.928,8.361,20.291c0,7.344-2.795,14.688-8.361,20.291C597.091,713.208,589.798,716,582.5,716
                                      s-14.593-2.792-20.139-8.396L370.649,514.632C332.043,544.851,283.687,563,231,563C105.088,563,3,460.241,3,333.5
                                      S105.088,104,231,104z M231,505.625c94.295,0,171-77.208,171-172.125s-76.705-172.125-171-172.125
                                      c-94.295,0-171,77.208-171,172.125S136.705,505.625,231,505.625z"/>
                                                                                </g>
                                                                            </g>
                                                                        </g>
                                                                    </svg>
                                                                </a>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="header-search-overlay hide">
                                    <div className="container">
                                        <div className="header-search-overlay-wrap">
                                            <form className="searchform" onSubmit={this.handleSubmit}>
                                                <input type="search" className="searchinput" name="s" value={this.state.value} autoComplete="off" placeholder="Recherche..." onChange={this.handleChange}/>
                                                <input type="submit" className="searchsubmit hidden" name="submit" value="Search"/>
                                            </form>
                                            <button type="button" className="close">
                                                <span aria-hidden="true" className="fa fa-times"></span>
                                                <span className="sr-only">Close</span>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
                </header>
            </div>
        );
    }
}
export default withSitecoreContext()(GraphQLData(HorizontalMenuQuery, { name: 'MenuQuery' })(HorizontalMenu));
