import React from "react";
import {Placeholder, Text, withPlaceholder} from "@sitecore-jss/sitecore-jss-react";
import "./ListOfPush.scss";
const ListOfPush = props => {
  
  return (
      <React.Fragment>
          <div className="titleContainer pt-6">
              <div className="col-md-12 pad">
                  <svg className="vectorDashes" preserveAspectRatio="xMinYMin meet" viewBox="0 0 211 197">
                      <path d="M55,0 l65,0 l-55,250 l-65,0 z"></path>
                      <path d="M146,0 l65,0 l-55,250 l-65,0 z"></path>
                  </svg>
                  <Text field={props.fields && props.fields.Title} tag="h2" />
              </div>
          </div>
          <div className="section counter-block__container">
              <div className="container-fluid">
                  <div className="row centerBlock">
                      <Placeholder
                          name="push-items-placeholder"
                          rendering={props.rendering}
                      />
                  </div>
              </div>
          </div>
      </React.Fragment>
  );
};

export default withPlaceholder("push-items-placeholder")(ListOfPush);
