import React from "react";
import {Image, Text} from "@sitecore-jss/sitecore-jss-react";
import "./HeadingBanner.scss";
const HeadingBanner = props => {
  
  const { designVariant } = props.fields;
  return (
    <div
      className={"heading-background bg-2 "+ designVariant.value} style={{backgroundImage: `url(`+props.fields.backgroundImage.value.src+`)`}}
    >
      <div className="headingBannerCustom">
        <div className="row">
          <div className="col-md-12">
            <div className="heading-wrap">
              <div className="page-title">
                <Text field={props.fields.Title} tag="h1" />
                <span className="subtitle">
                  <Text field={props.fields.subtitle} />{" "}
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HeadingBanner;
