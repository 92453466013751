import React from 'react';
import { Text, Image, withSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
import { css } from '@emotion/core';
import { DotLoader } from 'react-spinners';
import Moment from 'react-moment';
import ArticleCategoriesNavigation from '../ArticleCategoriesNavigation/index.js';
import i18next from 'i18next';
import ReactComponent from "../../ReactComponent";
import { loader as gqlLoader } from "graphql.macro";
import { Query } from "react-apollo";
import NavLinkAdv from "../../utils/NavLinkAdv";

const ArticlesNavigationQuery = gqlLoader('./query.graphql');
const override = css`
  margin: auto;
`;

class ArticlesNavigation extends ReactComponent {

    constructor(props) {
        super(props);
        this.state = {
            isLoaded: false,
            nbLoad: null,
            items: null,
            showButton: true
        };
    }

    componentDidMount() {
        if (this.props.fields.ArticleReadMore.value !== "") {
            this.setState({
                nbLoad: parseInt(this.props.fields.ArticleReadMore.value)
            })
        }
    }

    loadMore() {
        if (this.state.nbLoad !== null) {

            this.setState({
                nbLoad: this.state.nbLoad + parseInt(this.props.fields.ArticleReadMore.value)
            });

            if (this.state.nbLoad >= (this.state.items.length - parseInt(this.props.fields.ArticleReadMore.value))) {
                this.setState({
                    showButton: false
                });
            }
        }
    }

    render() {
        
        return (
            <>
                <div className="row_custom_small headingArticles">
                    <h1 className="col-md-12 text-center title__custom__articles">
                        <Text field={this.props.fields && this.props.fields.Heading} /> <small> <Text field={this.props.sitecoreContext.route.fields.articleTitle && this.props.sitecoreContext.route.fields.articleTitle} /></small></h1>
                </div>
                <div className="row_custom_small">
                    <div className="col-md-12">
                        <ArticleCategoriesNavigation />
                    </div>
                </div>
                <div className="row_custom_small">
                    <div className="col-md-12 text-left all-articles__custom">
                        <Query query={ArticlesNavigationQuery} variables={{ indexname: this.indexName, categoryId: this.props.sitecoreContext.route.itemId.split('-').join('').toLowerCase() }}>
                            {({ loading, error, data }) => {
                                if (loading)
                                    return (
                                        <div className='sweet-loading' style={{ marginTop: '50px', marginBottom: '50px' }}>
                                            <DotLoader
                                                css={override}
                                                sizeUnit={"px"}
                                                size={50}
                                                color={'#e1191c'}
                                                loading={!this.state.isLoaded}
                                            />
                                        </div>
                                    );
                                if (error)
                                    return (
                                        <div>Error: {error.message}</div>
                                    );

                                if (this.state.items === null) {
                                    this.state.items = data.product.results.items;
                                }

                                let dataToload;

                                if (this.state.items === null) {
                                    dataToload = data.product.results.items;
                                } else {
                                    dataToload = data.product.results.items;
                                }
                                
                                return (
                                    dataToload &&
                                    (this.state.nbLoad ? dataToload.slice(0, this.state.nbLoad) : dataToload).map(function (article, idx) {
                                        return (
                                            <React.Fragment key={idx}>
                                                <NavLinkAdv to={article.item && article.item.url} className="col-md-4 item__article__only">
                                                    <img src={article.item && article.item.articleImage && article.item.articleImage.src} className="img_custom_full" />
                                                    <Text field={article.item && article.item.articleTitle} tag="h1" />
                                                    <button className="btn btn-oro">{i18next.t("SeeMoreButton")}</button>
                                                </NavLinkAdv>
                                           </React.Fragment>
                                        )
                                    })
                                )
                            }}
                        </Query>
                    </div>
                    {
                        this.state.showButton &&
                        this.state.nbLoad &&
                        <div className="col-md-12 text-center p-5">
                            <a className="btn load-more" onClick={() => this.loadMore()}>{i18next.t('load-more-article')}</a>
                        </div>
                    }
                </div>
            </>
        )
    }
}

export default withSitecoreContext()(ArticlesNavigation);
