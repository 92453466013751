import React from "react";
import { withSitecoreContext } from "@sitecore-jss/sitecore-jss-react";
import NavLinkAdv from "../../utils/NavLinkAdv";
import "./ParentRoute.scss";
import ReactComponent from "../../ReactComponent";
import { loader as gqlLoader } from "graphql.macro";
import { Query } from "react-apollo";
import {DotLoader} from "react-spinners";
import {css} from "@emotion/core";

const BreadcrumbsQuery = gqlLoader('./query.graphql');
const override = css`
  margin: auto;
`;
class ParentRoute extends ReactComponent {
  constructor(props) {
    super(props);

    this.state = {
      error: null,
      isLoaded: false,
      itemdata: null,
      currentId: "",
      ListParents: ""
    };
  }


  render() {
 
    return (
      <>
        <nav className="breadcrumb__custom">
          
            <Query query={BreadcrumbsQuery} variables={{currrentContextItem: this.props.CurrentId}}>
              {({ loading, error, data}) => {
                          if (loading)
                              return(
                                  <div className='sweet-loading' style={{marginTop:'50px', marginBottom: '50px'}}>
                                      <DotLoader
                                          css={override}
                                          sizeUnit={"px"}
                                          size={50}
                                          color={"#0a4f91"}
                                          loading={!this.state.isLoaded}
                                      />
                                  </div>
                              );
                          if (error) 
                          return (
                              <div>Error: {error.message}</div>
                          );
                          let dataForBreadcrumbs = data && data.breadcrumbs;
                          return (
                            dataForBreadcrumbs &&
                              JSON.parse(dataForBreadcrumbs).ListParents.map((item, index) => (
                                <React.Fragment key={index}>
                                  <NavLinkAdv to={item.Link} key={index}>
                                    {item.Text}
                                  </NavLinkAdv>
                                  &nbsp;>&nbsp;
                                </React.Fragment>
                              ))
                          );
              
              }}

            </Query>
          <span className={"last_crumbs"}>{this.props.CurrentItemName}</span>
        </nav>
      </>
    );
  }
}
export default withSitecoreContext()(ParentRoute);
