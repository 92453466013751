import React from "react";
import {
  Text,
  RichText,
  withSitecoreContext
} from "@sitecore-jss/sitecore-jss-react";
import i18next from 'i18next';
import "./ProductCharacteristics.scss";
import Flag from '../../assets/ProductCharacteristics/flag.png';

class ProductCharacteristics extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            isActive: true
        };
        this.changeActiveFalse = this.changeActiveFalse.bind(this);
        this.toggleActive = this.toggleActive.bind(this);
    }

    changeActiveFalse() {
        this.setState({
            isActive: !this.state.isActive
        });
    }

    toggleActive(elem) {
      document.querySelectorAll(".nav-tabs li").forEach((el) => el.classList.remove("actived"))
      elem.currentTarget.classList.toggle("actived");
    }

  render() {
      const designVariant = this.props.sitecoreContext.route.fields.designVariant === undefined ? "" : this.props.sitecoreContext.route.fields.designVariant && this.props.sitecoreContext.route.fields.designVariant.value;
    return (
            designVariant.length > 0 ?
                <div className={`tabbable commerce-tabs container productCharactTabs ${designVariant}`}>
                    <ul className="nav nav-tabs">
                        <li onClick={(el) => this.toggleActive(el)} className="actived">
                            <a data-toggle="tab" href={`#desc`} >
                              {i18next.t("product-description")}
                            </a>
                        </li>
                        <li onClick={(el) => this.toggleActive(el)}>
                            <a data-toggle="tab" href={`#ing`} >
                              {i18next.t("product-ldi-decryptee")}
                            </a>
                        </li>
                        <li onClick={(el) => this.toggleActive(el)}>
                            <a data-toggle="tab" href={`#env`} >
                            {i18next.t("product-impact-environment")}
                            </a>
                        </li>
                    </ul>
                    <div className="tab-content">
                        <div className={this.props.isActive ? "tab-pane fade in" : "tab-pane fade in active"} id={`desc`}>
                            <RichText
                                field={
                                    this.props.sitecoreContext.route.fields
                                        .productLongDescription
                                }
                            />
                        </div>
                        <div className={this.props.isActive ? "tab-pane fade in active" : "tab-pane fade in"} id={`ing`}>
                            <RichText
                                field={
                                    this.props.sitecoreContext.route.fields
                                        .productIngredients
                                }
                            />
                        </div>
                        <div className={this.props.isActive ? "tab-pane fade in active" : "tab-pane fade in"} id={`env`}>
                            <RichText
                                field={
                                    this.props.sitecoreContext.route.fields
                                        .environmentText
                                }
                            />
                        </div>
                    </div>
                </div>
            :
                <div className="tabbable commerce-tabs single-commerce-tabs">
                    <div className="tab-content">
                    <div
                      id={`tab-0`}
                      className="single-commerce-tabs__content"
                      >
                      <div className="product-description">
                        <h3 className="single-commerce-tabs__title">
                          {i18next.t("product-description")}
                        </h3>
                        <div className="single-commerce-tabs__desc">
                          <RichText
                            field={
                              this.props.sitecoreContext.route.fields
                                .productLongDescription
                            }
                          />
                        </div>
                      </div>
                      <div className="ldi-decryptee">
                        <h3 className="single-commerce-tabs__title">
                          {i18next.t("product-ldi-decryptee")}
                        </h3>
                        <div className="single-commerce-tabs__desc">
                          <RichText
                            field={
                              this.props.sitecoreContext.route.fields
                                .productIngredients
                            }
                          />
                        </div>
                      </div>
                      <div className="environmentText">
                        <h3 className="single-commerce-tabs__title">
                          {i18next.t("product-impact-environment")}
                        </h3>
                        <div className="single-commerce-tabs__desc">
                          <RichText
                            field={
                              this.props.sitecoreContext.route.fields
                                .environmentText
                            }
                          />
                        </div>
                      </div>
                    </div>

            </div>
            <div className="col-md-12 text-center">
              <img src={Flag} alt=""/>
            </div>
          </div>
    );
  }
}

export default withSitecoreContext()(ProductCharacteristics);