import { loader as gqlLoader } from 'graphql.macro';
import packageJson from '../../../package.json';
import SCJSS from '../../temp/config';

const ACTIVE_RATING_STATUS_QUERY = gqlLoader('./activeRatingStatus.graphql');

class Common {
    static async getActiveRatingStatusId(args){
        let QUERY = ACTIVE_RATING_STATUS_QUERY.loc.source.body;
        QUERY = QUERY.replace("$indexName",  '"sitecore_' + args.sitecoreContext.route.databaseName + '_' + packageJson.config.appName.split('-').join( '_') + '_index"');
        QUERY = QUERY.replace("$rootPath", '"sitecore/content/' + packageJson.config.appName + '/"');

        const response = await fetch(SCJSS.graphQLEndpoint + "&query=" + QUERY);
        return response.json();
    }
}

export default Common;
