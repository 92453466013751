export function getRelativePaths(url) {
    if (typeof(window) !== "undefined") {
        const domain = window.location.origin + ":443";
        return url.replace(domain, '');
    }
    else{
    
    return url;
    }
}
