import React from "react";
import {
  Image,
  Text,
  RichText,
  withSitecoreContext
} from "@sitecore-jss/sitecore-jss-react";
import "./TitleAndDescriptionAndImage.scss";
const TitleAndDescriptionAndImage = props => {
  const { designVariant } = props.fields;
  
  return (
    <div className={`row box-1 ${designVariant && designVariant !== undefined && designVariant.value}`}>
      <div className="row section3">
        <div className="col-md-12 pad text-center">
          <font
            color={
              props.fields.TitleColor &&
              props.fields.TitleColor.fields.cssColor.value
            }>
            {props.fields.Title && props.fields.Title.value !== "" && (
                <React.Fragment>
                    <svg className="vectorDashes" preserveAspectRatio="xMinYMin meet" viewBox="0 0 211 197">
                        <path d="M55,0 l65,0 l-55,250 l-65,0 z"></path>
                        <path d="M146,0 l65,0 l-55,250 l-65,0 z"></path>
                    </svg>
                    <Text field={props.fields.Title} tag="h2" className="freeland" />
                </React.Fragment>
            )}
            {props.fields.ImageLocation &&
              props.fields.Image &&
              props.fields.ImageLocation.fields.title.value ===
                "Before Description" && (
                <img src={props.fields.Image && props.fields.Image.value.src}  />
              )}
            {props.fields.Description && props.fields.Description.value !== "" && (
              <blockquote>
                {" "}
                <RichText
                  field={props.fields.Description}
                  tag="p"
                  className="helv_lt"
                />{" "}
              </blockquote>
            )}
          </font>
          {props.fields.ImageLocation &&
            props.fields.Image &&
            props.fields.ImageLocation.fields.title.value !==
              "Before Description" && (
              <img src={props.fields.Image && props.fields.Image.value.src}  />
            )}
        </div>
      </div>
    </div>
  );
};

export default withSitecoreContext()(TitleAndDescriptionAndImage);
