import React from "react";
import {
  Placeholder,
  withSitecoreContext
} from "@sitecore-jss/sitecore-jss-react";
import "./ProductFinder-LayoutWrapper.scss";
import { observeProductFinder } from '../Tagging';
const displayNone = {
  display: 'none',
};
const displayBlock = {
  display: 'block',
};

class ProductFinderLayoutWrapper extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      facets: [],
      filters: [],
      resetFilters: false,
      rangeFilter: ['*', '*'],
      updateRange: true,
      keyword: '',
      resetSearch: false,
      selectedCategoriesText: '',
      showProducts: false,
      sidebar: false,
      urlTagId: '',
      categoriesSession: [],
      categoriesSessionNames: '',
    };
    this.onFacetsChange = this.onFacetsChange.bind(this);
    this.onFiltersChange = this.onFiltersChange.bind(this);
    this.resetFilters = this.resetFilters.bind(this);
    this.handleShowProducts = this.handleShowProducts.bind(this);
    this.onRangeChange = this.onRangeChange.bind(this);
    this.handleUpdateOnCategory = this.handleUpdateOnCategory.bind(this);
  }

  onFacetsChange(facets) {
    this.setState({
      facets: facets,
    });
    this.state = {
      shown: true,
    };
  }
  componentDidMount() {
    window.addEventListener('resize', this.resize.bind(this));
    this.resize();

    observeProductFinder(this.props.sitecoreContext); //GTM
  }
  componentDidUpdate(prevProps, prevState) {
    if (prevState.showProducts !== this.state.showProducts) {
      this.setState({
        showProducts: true,
      });
    }
    const url = window.location.href;
    let filterSearch = '';
    if (url.includes('?search=')) {
      filterSearch = url.substring(url.indexOf('=') + 1);
    }
    if (prevState.keyword !== filterSearch) {
      this.setState({
        keyword: filterSearch,
      });
    }
  }
  onRangeChange(range) {
    this.setState({
      rangeFilter: range,
      updateRange: false,
    });
  }

  handleUpdateOnCategory(categories, categoryNames) {
    let categoryText = '';
    if (categoryNames.length > 1 && categoryNames[0] !== '' && categoryNames[1] !== '') {
      categoryText = categoryNames.join(', ');
    } else if (categoryNames.length > 1 && categoryNames[0] !== '' && categoryNames[1] === '') {
      categoryText = categoryNames[0];
    } else if (categoryNames.length > 1 && categoryNames[0] === '' && categoryNames[1] !== '') {
      categoryText = categoryNames[1];
    } else if (categoryNames.length > 1 && categoryNames[0] === '' && categoryNames[1] === '') {
      categoryText = '';
    } else {
      categoryText = categoryNames[0];
    }

    this.setState({
      categoriesSession: categories,
      categoriesSessionNames: categoryText,
    });
  }
  onFiltersChange(filters) {
    if (this.state.filters.length === 0) {
      this.setState({
        filters: [...this.state.filters, filters],
        updateRange: true,
      });
    } else {
      let found = false;
      let updatedFilters = this.state.filters.map((obj) => {
        if (obj.template === filters.template) {
          found = true;
          return filters;
        } else return obj;
      });
      if (!found) {
        updatedFilters.push(filters);
      }
      this.setState({
        filters: updatedFilters,
        updateRange: true,
        rangeFilter: found ? ['*', '*'] : this.state.rangeFilter,
      });
    }
  }

  resetFilters() {
    let reset,
      resetSearch = true;
    if (this.state.resetFilters) reset = false;
    else reset = true;
    if (this.state.resetSearch) resetSearch = false;
    else resetSearch = true;
    this.setState({
      filters: [],
      resetFilters: reset,
      resetSearch: resetSearch,
      rangeFilter: ['*', '*'],
      keyword: '',
    });
    //console.log(this.state.filters)
  }

  handleMobileClick(e) {
    // console.log(e.target);
    if (e.target.parentElement.nextElementSibling.classList.contains('filters')) {
      e.target.parentElement.nextElementSibling.classList.remove('filters');
      e.target.parentElement.nextElementSibling.nextElementSibling.classList.add('hidden');
    } else {
      e.target.parentElement.nextElementSibling.classList.add('filters');
      e.target.parentElement.nextElementSibling.nextElementSibling.classList.remove('hidden');
      e.target.style.color = '#444';
    }
  }

  handleShowProducts(show) {
    if (show == true) {
      this.setState({
        showProducts: true,
      });
    }
  }

  resize() {
    let currentHideNav = window.innerWidth > 450;
    if (currentHideNav) {
      this.setState({ sidebar: true });
    }
  }

  render() {
    let sidebar = {
      display: this.state.sidebar === true ? 'block' : 'none',
    };

    return (
      <div className="container for_tab">
        <div>
          <div className="col-md-12 col-sm-12 filters fadeInRight">
            <div className="main-sidebar no-border">
              <Placeholder
                name="sidebar-left"
                rendering={this.props.rendering}
                facets={this.state.facets}
                onFiltersChange={this.onFiltersChange}
                onRangeChange={this.onRangeChange}
                resetFilters={this.state.resetFilters}
                resetSearch={this.state.resetSearch}
                updateRange={this.state.updateRange}
                range={this.state.rangeFilter}
                handleUpdateOnCategory={this.handleUpdateOnCategory}
                handleShowProducts={this.handleShowProducts}
              />
            </div>
            <div className="list__product__item">
              <Placeholder
                name="main-content"
                rendering={this.props.rendering}
                filters={this.state.filters}
                rangeFilter={this.state.rangeFilter}
                onFacetsChange={this.onFacetsChange}
                keyword={this.state.keyword}
                categoriesSession={this.state.categoriesSession}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withSitecoreContext()(ProductFinderLayoutWrapper);
