import React from "react";
import {
  RichText, Text,
  withSitecoreContext
} from "@sitecore-jss/sitecore-jss-react";
import './richTextComponent.scss';

class RichTextComponent extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    const designVariant =
      this.props.fields.designVariant === undefined
        ? ""
        : this.props.fields.designVariant &&
          this.props.fields.designVariant.value;
    return (
        designVariant === "marque" ?
          <div className={`row ${designVariant}`} id={this.props.fields.blockAnchorID && this.props.fields.blockAnchorID.value}>
            <div className="col-md-12 text-center all-articles__custom richtext_custom marque">
              <div className={"richFlex"}>
                <svg className="vectorDashes" preserveAspectRatio="xMinYMin meet" viewBox="0 0 211 197">
                  <path d="M55,0 l65,0 l-55,250 l-65,0 z"></path>
                  <path d="M146,0 l65,0 l-55,250 l-65,0 z"></path>
                </svg>
                <Text field={this.props.fields.Title && this.props.fields.Title} tag="h2" />
              </div>
              {
                this.props.fields.Text &&
                  <RichText
                      className="all-articles__content"
                      field={this.props.fields.Text}
                  />
              }
            </div>
          </div>
          :
              <div className={`row ${designVariant}`} id={this.props.fields.blockAnchorID && this.props.fields.blockAnchorID.value}>
                <div className="col-md-12 text-center all-articles__custom richtext_custom otherRich">
                  <RichText field={this.props.fields.Title && this.props.fields.Title} tag={"h2"}/>
                  <RichText
                      className="all-articles__content"
                      field={this.props.fields.Text && this.props.fields.Text}
                  />
                </div>
              </div>

    );
  }
}

export default withSitecoreContext()(RichTextComponent);
