import React from 'react';
import { Text, withSitecoreContext, RichText, isExperienceEditorActive } from '@sitecore-jss/sitecore-jss-react';
import { Formik, Field } from "formik";
import { ApolloConsumer } from 'react-apollo';
import './ratings.css';
import './comment.css'
import StarRating from '../StarRating';
import RatingReviewsFilter from '../RatingReviewsFilter';
import i18next from 'i18next';
import "./RatingReviews.scss";
import { handleSubmitReview } from '../Tagging';

import ReactComponent from '../../ReactComponent';
import { loader as gqlLoader } from 'graphql.macro';
import { Query } from 'react-apollo';
import { DotLoader } from 'react-spinners';
import { css } from '@emotion/core';


const override = css`
  margin: auto;
`;


const RatingsReviewQuery = gqlLoader('./query.graphql');

// @todo Move ratings & comments

const RATINGS_QUERY = gqlLoader('./RatingReviews.graphql');



class RatingReviews extends ReactComponent {

    constructor(props) {
        super(props);

        this.state = {
            data: null,
            error: '',
            show: true,
            status: "",
            rating: "",
            clickedStar: "",
            username: "",
            commentValue: "",
            isLoaded: false,
            itemdata: null,
            templateId: null,
            nbLoad: null,
            showButton: true
        }
        this.onStarClick = this.onStarClick.bind(this);
        this.userNameChange = this.userNameChange.bind(this);
        this.commentChange = this.commentChange.bind(this);
        this.updateData = this.updateData.bind(this);
        this.loadMore = this.loadMore.bind(this);
    }

    componentDidMount() {
        this.activeRatingStatusId().then(res => {
            if (res) {
                this.setState({
                    templateId: res.data.search.results.items[0].item.id.toLowerCase(),
                    nbLoad: this.props.fields.NbToLoad && parseInt(this.props.fields.NbToLoad.value)
                });
            }
        });
    }

    onStarClick(event) {

        var ratingValue = event.target.parentElement.getAttribute("data-rating")


        var clickedStarId = event.target.id
        this.setState({
            rating: ratingValue,
            clickedStar: clickedStarId

        })
    }

    userNameChange(event) {
        this.setState({ username: event.target.value });
    }
    commentChange(event) {
        this.setState({ commentValue: event.target.value });
    }
    updateData(data) {
        this.setState({
            itemdata: data
        });

        if (data) {
            if (data.length <= this.state.nbLoad) {
                this.setState({
                    showButton: false
                });
            } else {
                this.setState({
                    showButton: true
                });
            }
        }
    }
    loadMore(data) {
        if (this.state.nbLoad !== null) {

            this.setState({
                nbLoad: this.state.nbLoad + parseInt(this.props.fields.NbToLoad.value)
            });

            if (this.state.nbLoad >= (this.state.itemdata.length - parseInt(this.props.fields.NbToLoad.value))) {
                this.setState({
                    showButton: false
                });
            }
        }
    }

    render() {

        const designVariant = this.props.fields.designVariant === undefined ? "" : this.props.fields.designVariant && this.props.fields.designVariant.value;
        var productId = this.props.sitecoreContext.itemId.replace(/-/g, "").toLowerCase();
        return (
            <>
                <div className={`container margin_custom ratingB ${designVariant}`}>

                    <div className="single-comment">

                        <div className="single-comment--container">
                            <div className="title-review-container">
                                <h2 className="title-review"><Text field={this.props.fields.RatingAndReviewTitle} /></h2>
                            </div>
                            <div id="reviews" className="Reviews">
                                <div id="comments">
                                    <Query query={RatingsReviewQuery} variables={{
                                        rootPath: '/sitecore/content/' + this.appName + '/Content/Rating and Reviews',
                                        indexname: this.indexName,
                                        productId: productId,
                                        ratingstatusId: this.state.templateId !== null ? this.state.templateId : ""
                                    }} >
                                        {({ loading, error, data }) => {
                                            if (loading)
                                                return (
                                                    <div className='sweet-loading' style={{ marginTop: '50px', marginBottom: '50px' }}>
                                                        <DotLoader
                                                            css={override}
                                                            sizeUnit={"px"}
                                                            size={50}
                                                            color={"#e1191c"}
                                                            loading={!this.state.isLoaded}
                                                        />
                                                    </div>
                                                );

                                            if (error)
                                                return (
                                                    <div>Error: {error.message}</div>
                                                );

                                            let items = data.search.results.items;

                                            let dataToload;

                                            if (this.state.itemdata === null) {
                                                dataToload = data.search.results.items;
                                            } else {
                                                dataToload = this.state.itemdata;
                                            }

                                            if (items.length === 0)
                                                return (
                                                    isExperienceEditorActive() && (
                                                        <div>{i18next.t("NoTimelineFoundLabel")}</div>
                                                    )
                                                );

                                            return (
                                                <>
                                                    <RatingReviewsFilter onFilterChange={this.updateData} comments={dataToload} />
                                                    <ol className="commentlist single-comment__list">
                                                        {
                                                            dataToload &&
                                                            (this.state.nbLoad ? dataToload.slice(0, this.state.nbLoad) : dataToload).map((commentItem, index) => (
                                                                <li className="review even thread-even depth-1" key={index} id="li-comment-36">
                                                                    <p className="single-comment__review__head">
                                                                        <strong className="single-comment__review__author">{commentItem.item.userName.value} </strong>
                                                                        <time className="single-comment__review__published-date" >{commentItem.item.creationDate.value}</time>
                                                                    </p>
                                                                    <div id="comment-36" className="comment_container">
                                                                        <div className="comment-text">
                                                                            <div className="comment-text__stars">
                                                                                <StarRating value={commentItem.item.rating.numberValue} />
                                                                            </div>
                                                                            <div className="description comment-text__description">
                                                                                <p>{commentItem.item.comment.value}</p>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </li>
                                                            ))
                                                        }
                                                    </ol>
                                                    {
                                                        this.state.nbLoad &&
                                                        this.state.showButton &&
                                                        <div className="col-12 text-center pb-5">
                                                            <button className={"btn load-more"} onClick={() => this.loadMore(dataToload)}>{i18next.t('load-more')}</button>
                                                        </div>
                                                    }
                                                </>
                                            );
                                        }
                                        }
                                    </Query>



                                </div>
                                <div id="review_form_wrapper">
                                    <a name="reviewForm"></a>
                                    <div id="review_form">
                                        <div id="respond" className="comment-respond">
                                            <span id="reply-title" className="comment-reply-title same__font">
                                                <Text field={this.props.fields.RatingLabel} />
                                            </span>
                                            {this.state.show == false && this.state.status == "success" &&
                                                <div className="alert alert-success" role="alert">
                                                    {i18next.t('ratingreviews-successfull-submission')}
                                                </div>
                                            }
                                            {this.state.show && this.state.show == true &&
                                                <ApolloConsumer>
                                                    {client => (
                                                        <Formik
                                                            enableReinitialize
                                                            initialValues={{ userName: this.state.username, rating: this.state.rating, comment: this.state.commentValue, product: "", privacy: false }}
                                                            onSubmit={async (values, { setErrors, resetForm, setSubmitting }) => {

                                                                const { data } = await client.query({
                                                                    query: RATINGS_QUERY,
                                                                    variables: { userName: values.userName, rating: values.rating, comment: values.comment, product: this.props.sitecoreContext.itemId }
                                                                });
                                                                if (data.ratingreviews == "Review Submitted Successfully") {
                                                                    handleSubmitReview(
                                                                      this.props.sitecoreContext.route.displayName,
                                                                      this.props.sitecoreContext.route.fields.codeEan.value
                                                                    );
                                                                    this.setState({
                                                                        show: false,
                                                                        status: "success"
                                                                    })
                                                                }
                                                                else {
                                                                    setErrors({ submit: "Error sending review." });
                                                                    setSubmitting(false);
                                                                }

                                                            }}
                                                            validate={values => {
                                                                let errors = {};
                                                                if (!values.privacy) {
                                                                    errors.privacy = i18next.t('ChampsObligatoires');
                                                                }
                                                                if (!values.comment) {
                                                                    errors.comment = i18next.t('ChampsObligatoires');
                                                                }


                                                                return errors;
                                                            }}
                                                        >
                                                            {props => {
                                                                const {
                                                                    values,
                                                                    touched,
                                                                    errors,
                                                                    isSubmitting,
                                                                    handleChange,
                                                                    handleBlur,
                                                                    handleSubmit
                                                                } = props;
                                                                return (
                                                                    <>
                                                                        <div className="">

                                                                            <form id="commentform" className="comment-form another__font" onSubmit={handleSubmit}>
                                                                                <div className="comment-notes helv_lt">
                                                                                    <Text field={this.props.fields.RequiredFieldsInfo} /><span className="required">*</span>
                                                                                </div>
                                                                                <div className="comment-form-rating same__font">
                                                                                    <label htmlFor="rating" className="helv_lt">{i18next.t('ratingreviews-your-score')}</label>
                                                                                    <p className="stars another__font">
                                                                                        <span>

                                                                                            <a className="star-1 same__font" data-rating="1" onClick={this.onStarClick}>
                                                                                                <i className={"rating-1" == this.state.clickedStar || 1 <= this.state.rating ? "fa fa-star" : "fa fa-star-o"} id="rating-1" ></i>
                                                                                            </a>
                                                                                            <a className="star-2 same__font" data-rating="2" onClick={this.onStarClick}>
                                                                                                <i className={"rating-2" == this.state.clickedStar || 2 <= this.state.rating ? "fa fa-star" : "fa fa-star-o"} id="rating-2"></i>
                                                                                            </a>
                                                                                            <a className="star-3 same__font" data-rating="3" onClick={this.onStarClick}>
                                                                                                <i className={"rating-3" == this.state.clickedStar || 3 <= this.state.rating ? "fa fa-star" : "fa fa-star-o"} id="rating-3" ></i>
                                                                                            </a>
                                                                                            <a className="star-4 same__font" data-rating="4" onClick={this.onStarClick}>
                                                                                                <i className={"rating-4" == this.state.clickedStar || 4 <= this.state.rating ? "fa fa-star" : "fa fa-star-o"} id="rating-4"></i>
                                                                                            </a>
                                                                                            <a className="star-5 same__font" data-rating="5" onClick={this.onStarClick}>
                                                                                                <i className={"rating-5" == this.state.clickedStar ? "fa fa-star" : "fa fa-star-o"} id="rating-5"></i>
                                                                                            </a>
                                                                                        </span>
                                                                                    </p>

                                                                                </div>
                                                                                <div className="comment-form-comment another__font">
                                                                                    <textarea id="comment" name="comment" cols="45" rows="8" placeholder={i18next.t('Comment')} required="" value={this.state.commentValue} onChange={this.commentChange}
                                                                                        className="helv_std"></textarea>
                                                                                    {errors.comment && touched.comment && (
                                                                                        <p className="alert alert-danger" role="alert">
                                                                                            {errors.comment}
                                                                                        </p>

                                                                                    )}
                                                                                </div>
                                                                                <p className="form-control-wrap">

                                                                                </p>
                                                                                <div className="row input__nombre">
                                                                                    <p className="comment-form-author another__font">
                                                                                        <input type="text" name="userName" id="userName" size="40" className="helv_std" placeholder={i18next.t('NomPrenom')} value={this.state.username} onChange={this.userNameChange} />
                                                                                    </p>
                                                                                </div>

                                                                                <div className="pprivacy same__font">
                                                                                    <input type="checkbox" id="privacy" name="privacy" value="privacy-key" checked={values.privacy == true} className="privacyBox helv_std" onChange={handleChange} /><RichText field={this.props.fields.PrivacyCheckBoxLabel} />
                                                                                    {errors.privacy && touched.privacy && (
                                                                                        <p className="alert alert-danger" role="alert">{errors.privacy}</p>
                                                                                    )}
                                                                                </div>
                                                                                <p>

                                                                                </p>
                                                                                <p className="form-submit another__font">
                                                                                    <input name="submit" type="submit" id="submit" className="btn isRedButton" value={i18next.t('Envoyer')} />
                                                                                    <input type="hidden" name="comment_post_ID" value="983" id="comment_post_ID" />
                                                                                    <input type="hidden" name="comment_parent" id="comment_parent" value="0" />
                                                                                </p>
                                                                            </form>
                                                                        </div>
                                                                    </>
                                                                );
                                                            }}
                                                        </Formik>
                                                    )}
                                                </ApolloConsumer>
                                            }



                                        </div>
                                        <div className="row single-comment__legal">
                                            <div className="single-comment__legal-title">{i18next.t('ratingreviews-legal-title')}</div>
                                            <div className="single-comment__legal-desc">
                                                <RichText field={this.props.fields.Privacy} />
                                            </div>
                                        </div>
                                    </div>
                                </div>


                                <div className="clear"></div>
                            </div>
                        </div>
                    </div>

                </div>
            </>
        )
    }
}

export default withSitecoreContext()(RatingReviews);
