import React from "react";
import { Text, Image } from "@sitecore-jss/sitecore-jss-react";
import StarRating from "../StarRating";
import { loader as gqlLoader } from "graphql.macro";
import GraphQLData from "../../lib/GraphQLData";
import ProductReviews from "../../utils/ProductReviews/index.js";
import NavLinkAdv from "../../utils/NavLinkAdv";
import { getRelativePaths } from "../../utils/Utils.js";
import i18next from 'i18next';
import "./BestRatedProducts.scss";
import { productClickTagging } from '../Tagging';
const BestRatedProductsQuery = gqlLoader('./BestRatedProductsQuery.graphql');

const BestRatedProducts = (props) => {
  const designVariant = props.fields.designVariant === undefined ? '' : props.fields.designVariant && props.fields.designVariant.value;
  const graphQLResult = props.bestRatedProductsQuery;
  const { datasource } = graphQLResult;
  return (
    <div className={`container bestCont ${designVariant}`}>
      <div className="bestrelated__product__custom home-bestsellers">
        <div className="col-md-12 pad">
          <svg className="vectorDashes" preserveAspectRatio="xMinYMin meet" viewBox="0 0 211 197">
            <path d="M55,0 l65,0 l-55,250 l-65,0 z"></path>
            <path d="M146,0 l65,0 l-55,250 l-65,0 z"></path>
          </svg>
          <Text field={props.fields.BestRatedProductsHeading} tag="h2" />
        </div>
        {datasource &&
          datasource.bestRatedProductsList.targetItems.map((productItem, index) => (
            <div
              data-gtm-name={productItem.title.jss.value}
              data-gtm-ean={productItem.codeEan.value}
              data-gtm-category={productItem.url.split('/')[2]}
              data-gtm-rating={productItem.rating?.numberValue}
              data-gtm-list="best-sellers"
              data-gtm-position={index+1}
              className={
                productItem.natural.value === '1'
                  ? 'portfolio-item product-container text-center isHoverGreen'
                  : 'portfolio-item product-container text-center isHoverRed'
              }
              key={index}>
              <NavLinkAdv
                className="portfolio-link"
                to={getRelativePaths(productItem.url)}
                onClick={() =>
                  productClickTagging(
                    productItem.title.jss.value,
                    productItem.codeEan.value,
                    productItem.url.split('/')[2],
                    productItem.rating?.numberValue,
                    'best-sellers',
                    index+1
                  )
                }>
                <img src={productItem.image1 && productItem.image1.src} className="img-fluid" />
                <div className="portfolio-caption min_font">
                  <Text field={productItem.title.jss} tag="h3" />
                  <Text field={productItem.beneficeProduit.jss} tag={'p'} />
                  <div className="portfolio-rating">
                    <StarRating value={productItem.rating.numberValue} />
                    <ProductReviews productId={productItem.id} />
                  </div>
                </div>
              </NavLinkAdv>
              {props.fields.IsSwaven && props.fields.IsSwaven.value === false ? (
                <a
                  href={productItem.url}
                  className={productItem.natural.value === '1' ? 'btn btn_custom_oro isGreenButton' : 'btn btn_custom_oro isRedButton'}>
                  {i18next.t('product-view-product')}
                </a>
              ) : (
                <a
                  data-eans={productItem.codeEan.value}
                  className={
                    productItem.natural.value === '1'
                      ? 'swn-tag-wtb-btn btn btn_custom_oro isGreenButton'
                      : 'swn-tag-wtb-btn btn btn_custom_oro isRedButton'
                  }>
                  {i18next.t('Acheter')}
                </a>
              )}
            </div>
          ))}
      </div>
    </div>
  );
};

export default GraphQLData(BestRatedProductsQuery, {
  name: "bestRatedProductsQuery"
})(BestRatedProducts);
