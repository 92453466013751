import React from "react";
import ReactComponent from "../../ReactComponent";
import {withSitecoreContext} from "@sitecore-jss/sitecore-jss-react";
import NavLink from "react-router-dom/NavLink";

class NavLinkAdvAdv extends ReactComponent{

    constructor(props) {
        super(props);
    }

    render() {
        return(
            <NavLink to={this.getUrlEmbeded(this.props.to)} className={this.props.className} key={this.props.key} onClick={this.props.onClick}>
                {this.props.children}
            </NavLink>
        )
    }

}

export default withSitecoreContext()(NavLinkAdvAdv);
