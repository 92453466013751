import React from "react";
import { Text } from "@sitecore-jss/sitecore-jss-react";

class ErrorContent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      value: ""
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleChange(event) {
    this.setState({ value: event.target.value });
  }

  handleSubmit(event) {
    window.location.href = "/search?search=" + this.state.value;
    event.preventDefault();
  }

  render() {
    const designVariant =
      this.props.fields.designVariant === undefined
        ? ""
        : this.props.fields.designVariant &&
          this.props.fields.designVariant.value;
    return (
      <div className={`not-found-wrapper ${designVariant}`}>
        <span className="not-found-title">
          <Text field={this.props.fields.Title} />
        </span>
        <span className="not-found-subtitle">
          <Text field={this.props.fields.SubTitle} />
        </span>
        <div className="widget widget_search">
          <p>
            <Text field={this.props.fields.ErrorMessage} />
          </p>
          <div className="search-wrapper">
            <form className="searchform" onSubmit={this.handleSubmit}>
              <label htmlFor="s" className="sr-only">
                Search
              </label>
              <input
                type="search"
                className="form-control"
                name="s"
                value={this.state.value}
                autoComplete="off"
                placeholder="Recherche..."
                onChange={this.handleChange}
              />
              <input
                type="submit"
                id="searchsubmit"
                className="hidden"
                name="submit"
                value="Search"
              />
            </form>
          </div>
        </div>
      </div>
    );
  }
}

export default ErrorContent;
