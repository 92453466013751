import React from "react";
import {RichText, Text, withSitecoreContext} from "@sitecore-jss/sitecore-jss-react";
import QueryBuilder from "../../utils/QueryBuilder.js";
import i18next from 'i18next';
import NavLinkAdv from "../../utils/NavLinkAdv";
import { getRelativePaths } from "../../utils/Utils.js";
import "./GlobalSearch.scss";
import {withRouter} from 'react-router';
import ReactComponent from "../../ReactComponent";
import { observeProductFinder, handleInternalSearch, productClickTagging } from '../Tagging';

class GlobalSearch extends ReactComponent {
  constructor(props) {
    super(props);
    this.state = {
      keyword: '',
      itemdata: [],
      tempKeyword: '',
    };
    this.onItemsChange = this.onItemsChange.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleClick = this.handleClick.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleClose = this.handleClose.bind(this);
  }

  onItemsChange(itemdata) {
    this.setState({ itemdata: itemdata });
  }

  componentDidMount() {
    const url = window.location.href;
    let keyword = '';
    if (url.includes('?search=')) {
      keyword = url.substring(url.indexOf('=') + 1);

      if (keyword.includes('#')) keyword = keyword.replace('#', '');
      this.setState({
        keyword: decodeURIComponent(keyword),
        tempKeyword: decodeURIComponent(keyword),
      });

      //GTM
     handleInternalSearch(decodeURIComponent(keyword))
    }

    observeProductFinder(this.props.sitecoreContext); // GTM tagging (product impression)
  }

  handleClick() {
    this.setState({
      keyword: this.state.tempKeyword,
    });
  }

  handleClose() {
    this.setState({
      tempKeyword: '',
    });
  }

  handleChange(e) {
    observeProductFinder(this.props.sitecoreContext); // GTM tagging (product impression)
    e.preventDefault();
    const params = new URLSearchParams(window.location.search);
    params.set('search', this.state.tempKeyword);
    this.props.history.push(this.props.location.pathname + '?' + e.target.value);
    this.setState({
      tempKeyword: e.target.value,
      keyword: this.state.tempKeyword,
    });

    //GTM
    handleInternalSearch(this.state.tempKeyword);
  }

  handleSubmit(e) {
    e.preventDefault();
    const params = new URLSearchParams(window.location.search);
    params.set('search', this.state.tempKeyword);
    this.props.history.push(this.props.location.pathname + '?' + params.toString());
    this.setState({
      keyword: this.state.tempKeyword,
    });

    //GTM
    handleInternalSearch(this.state.tempKeyword);
  }

  render() {
    let SITE_ROOTPATH = '/sitecore/content/' + this.appName;
    let QUERY =
      'query{ search: customSearch( index:"' +
      this.indexName +
      '", rootItem: "' +
      SITE_ROOTPATH +
      '", conditions: [ {name: "_templatename", value: "PostRoute", useor:true}, {name: "_templatename", value: "ProductRoute", useor:true}], keyword: "##keyword") { results { totalCount pageInfo { hasNextPage startCursor endCursor hasPreviousPage } items { path templateName item { id url name ... on PostRoute { postTitle { value } postThumbnailImageMedium { src alt } postShortDescription { value } } ... on ProductRoute { title { value } image1 { src alt } image2 { src alt } codeEan { value } rating { numberValue } productShortDescription { value } } date: field(name: "__Updated") { value } } } } } } ';

    const { keyword, itemdata } = this.state;

    let empty = null;
    if (itemdata.data && itemdata.data.search.results.items.length === 0) {
      empty = <div>Nous n'avons trouvé aucun produit correspondant à votre recherche</div>;
    }
    const designVariant =
      this.props.fields.designVariant === undefined ? '' : this.props.fields.designVariant && this.props.fields.designVariant.value;
    return (
      <div className={`${designVariant}`}>
        <div className="heading-container">
          <div
            className="heading-background"
            style={{
              backgroundImage: `url(` + this.props.fields.backgroundImage.value.src + `)`,
            }}>
            <div className="container">
              <div className="row">
                <div className="col-md-12">
                  <div className="heading-wrap">
                    <div className="page-title mb-3">
                      <Text tag="h1" field={this.props.fields.title} />
                    </div>
                    <div className="form-wrapper">
                      <form className="searchBar" onSubmit={this.handleSubmit}>
                        <div className="search-wrapper">
                          <input type="search" placeholder="VOTRE RECHERCHE..." value={this.state.tempKeyword} onChange={this.handleChange} />
                          <input type="submit" id="globalSearchSubmit" className="hidden" name="submit" value="Search"></input>
                          <i className="fa fa-search" onClick={this.handleClick}></i>
                          <i onClick={this.handleClose} className={this.state.tempKeyword === '' ? 'hidden' : 'fa fa-close'}></i>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="container">
          <div className="row">
            <div className="col-md-12 col-sm-12 main-wrap">
              <div className="main-content">
                <div className="posts" data-layout="grid">
                  <div className="search-grid">
                    {itemdata.data && empty && empty}
                    {itemdata.data &&
                      itemdata.data.search.results.items &&
                      itemdata.data.search.results.items.map((result, index) => {
                        if (result.item.postTitle)
                          return (
                            <div key={index} className=" col-md-4 col-sm-6 hentry ">
                              <div className="hentry-wrap">
                                <div className="entry-featured">
                                  <NavLinkAdv to={getRelativePaths(result.item.url)}>
                                    <img src={result.item.postThumbnailImageMedium.src} alt={result.item.postThumbnailImageMedium.alt} />
                                  </NavLinkAdv>
                                </div>
                                <div className="entry-info">
                                  <div className="entry-header">
                                    <h2 className="entry-title">
                                      <NavLinkAdv to={getRelativePaths(result.item.url)}>{result.item.postTitle.value}</NavLinkAdv>
                                    </h2>
                                  </div>
                                  {/*<div className="entry-content">*/}
                                  {/*  <RichText field={result.item.postShortDescription}/>*/}
                                  {/*</div>*/}
                                  <div className="clearfix">
                                    <div className="readmore-link">
                                      <NavLinkAdv to={getRelativePaths(result.item.url)}>Read More</NavLinkAdv>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          );
                        else if (result.item.title)
                          return (
                            <div
                              data-gtm-name={result.item.title.value}
                              data-gtm-ean={result.item.codeEan?.value}
                              data-gtm-category={result.item.url.split('/')[2]}
                              data-gtm-rating={result.item.rating?.numberValue}
                              data-gtm-list="list-result-range"
                              data-gtm-position={index+1}
                              key={index}
                              className=" col-md-4 col-sm-6 hentry product-container"
                              onClick={() =>
                                productClickTagging(
                                  result.item.title.value,
                                  result.item.codeEan?.value,
                                  result.item.url.split('/')[2],
                                  result.item.rating?.numberValue,
                                  "list-result-search",
                                  index+1
                                )
                              }>
                              <div className="hentry-wrap">
                                <div className="entry-featured">
                                  <NavLinkAdv to={getRelativePaths(result.item.url)}>
                                    <img src={result.item.image1.src} alt={result.item.image1.alt} />
                                  </NavLinkAdv>
                                </div>
                                <div className="entry-info">
                                  <div className="entry-header">
                                    <h2 className="entry-title">
                                      <NavLinkAdv to={getRelativePaths(result.item.url)}>{result.item.title.value}</NavLinkAdv>
                                    </h2>
                                  </div>
                                  {/*<div className="entry-content">*/}
                                  {/*  <RichText field={result.item.productShortDescription}/>*/}
                                  {/*</div>*/}
                                  <div className="clearfix">
                                    <div className="readmore-link">
                                      <NavLinkAdv to={getRelativePaths(result.item.url)}>{i18next.t('product-view-product')}</NavLinkAdv>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          );
                        return false;
                      })}
                    <QueryBuilder
                      onItemsChange={this.onItemsChange}
                      query={QUERY}
                      itemsPerPage={this.props.fields.itemsPerPage.value}
                      keyword={keyword}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withSitecoreContext()(withRouter(GlobalSearch));
