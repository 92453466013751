import React from "react";
import { Placeholder } from "@sitecore-jss/sitecore-jss-react";
import "./PostLayoutWrapper.scss";
const PostLayoutWrapper = props => {
  
  return (
    <div className="container single-blog__content">
      <div className="rowd">
        <div className="main-wrap">
          <div className="main-content">
            <Placeholder name="main-content" rendering={props.rendering} />
          </div>
        </div>
        <div className="sidebar-wrap">
          <div className="main-sidebar no-border">
            <Placeholder name="sidebar-right" rendering={props.rendering} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default PostLayoutWrapper;
