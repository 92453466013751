import React from 'react';

class InstagramImageItem extends React.Component {

  render() {
    return (
        <div className={`instagram-image-item sameWidth`}>
            <a href={this.props.item.imagePermalink.value} target="_blank">
              {/* <img src={this.props.item.imageMediaUrl.value}/> */}
              <div className="image" style={{backgroundImage: 'url('+ this.props.item.imageMediaUrl.value + ')'}}></div>
            </a>
        </div>
    )
  }
}

export default InstagramImageItem;
