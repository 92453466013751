import React from 'react';
import { withSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
import "./FilterCategories.scss";
import { DotLoader } from 'react-spinners';
import ReactComponent from "../../ReactComponent";
import { css } from '@emotion/core';
import { handleFilterClick } from '../Tagging';

const override = css`
  margin: auto;
`;

class FilterCategories extends ReactComponent {
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      isLoaded: false,
      itemdata: [],
      items: [],
      urlFilter: '',
    };
    this.handleClick = this.handleClick.bind(this);
    this.handleCategoriesChange = this.handleCategoriesChange.bind(this);
  }

  componentDidMount() {
    if (this.props.fields.filterOn) {
      let criteria = this.props.fields.filterOn.id.replace(/-/g, '');
      const url = window.location.href;
      let filterId = '';

      if (url.includes('?categoryId=') || url.includes('?brandId=') || url.includes('?tagId=')) {
        filterId = url.substring(url.indexOf('=') + 1);
        if (filterId.includes('#')) filterId = filterId.replace('#', '');
        if (filterId.includes('-')) filterId = filterId.replace(/-/g, '').toUpperCase();
      }

      let API_ENDPOINT = this.graphQLEndpoint + '&';
      let QUERY =
        'query{ filters:search( index:"' +
        this.indexName +
        '" fieldsEqual: [ {name: "_parent", value: "' +
        criteria +
        '"} ], rootItem: "/sitecore/content/' +
        this.appName +
        '/") { results { items { item { id name ...on ProductCategoryTemplate { title { value } } } } } } criteria:search( index:"' +
        this.indexName +
        '" fieldsEqual: [ {name: "_group", value: "' +
        criteria +
        '"} ], rootItem: "/sitecore/content/' +
        this.appName +
        '/") { results { items { item { id name template { name } ...on FolderproductCategory { displayName title { value }   } } } } } }';

      let req = {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          query: QUERY,
        }),
      };

      fetch(API_ENDPOINT, req)
        .then((res) => res.json())
        .then(
          (result) => {
            this.setState({
              isLoaded: true,
              itemdata: result,
              urlFilter: filterId,
            });
          },
          (error) => {
            this.setState({
              isLoaded: true,
              error,
            });
          }
        );
    }
  }

  handleCategoriesChange(event) {
    let currentSelectedCategory = event.target.value.split('__');
    let currentGroupId = currentSelectedCategory[0];
    let currentCategoryId = currentSelectedCategory[1] !== 'default' ? currentSelectedCategory[1] : '';
    let currentCategoryName = currentSelectedCategory[2] !== 'default' ? currentSelectedCategory[2] : '';

    handleFilterClick(event.target.getAttribute("data-filter-name"), currentCategoryName)

    // if the current page ID changed, then clear the session for filter categories
    let currentPageId = this.props.sitecoreContext.itemId;
    let pageItemId = sessionStorage.getItem('pageItemId');
    if (pageItemId !== currentPageId) {
      sessionStorage.setItem('pageItemId', currentPageId);

      sessionStorage.removeItem('filterCategories');
      sessionStorage.removeItem('filterCategoriesName');
    }

    let sessionCategories = sessionStorage.getItem('filterCategories');
    let sessionCategoryNames = sessionStorage.getItem('filterCategoriesName');

    if (sessionCategories === null) {
      // if session has no filtered categories, add the current selected category to the session
      sessionStorage.setItem(
        'filterCategories',
        JSON.stringify([
          {
            groupId: currentGroupId,
            categories: {
              categoryId: currentCategoryId.toLowerCase(),
            },
          },
        ])
      );
      sessionStorage.setItem(
        'filterCategoriesName',
        JSON.stringify([
          {
            groupId: currentGroupId,
            categoriesNames: {
              categoryName: currentCategoryName,
            },
          },
        ])
      );
    } else {
      let categoryItems = JSON.parse(sessionCategories);
      let categoryNames = JSON.parse(sessionCategoryNames);
      // if session has the same category as the current selected category, then update the previous selected category
      var categoryItemIndex = categoryItems.findIndex(function (item, i) {
        return item.groupId === currentGroupId;
      });

      if (categoryItemIndex > -1) {
        categoryItems[categoryItemIndex].categories = {
          categoryId: currentCategoryId,
        };
        categoryNames[categoryItemIndex].categoriesNames = {
          categoryName: currentCategoryName,
        };
        sessionStorage.setItem('filterCategories', JSON.stringify(categoryItems));
        sessionStorage.setItem('filterCategoriesName', JSON.stringify(categoryNames));
      }
      // if session does not have the same category as the current selected category, then add the category as a second filter
      else {
        categoryItems.push({
          groupId: currentGroupId,
          categories: {
            categoryId: currentCategoryId.toLowerCase(),
          },
        });
        categoryNames.push({
          groupId: currentGroupId,
          categoriesNames: {
            categoryName: currentCategoryName,
          },
        });
        sessionStorage.setItem('filterCategories', JSON.stringify(categoryItems));
        sessionStorage.setItem('filterCategoriesName', JSON.stringify(categoryNames));
      }
    }

    let array1 = [];
    let array2 = [];
    for (var ci of JSON.parse(sessionStorage.getItem('filterCategories'))) {
      array1.push(ci.categories.categoryId);
    }

    for (var cn of JSON.parse(sessionStorage.getItem('filterCategoriesName'))) {
      array2.push(cn.categoriesNames.categoryName);
    }

    this.props.handleUpdateOnCategory(array1, array2);
    let isPresent = false;
    this.props.handleShowProducts(true);

    for (var category of this.state.items) {
      if (category.item.title.value === currentCategoryName) {
        isPresent = true;
      }
    }
    if (isPresent) {
      this.setState((state) => {
        const items = state.items.filter((category) => currentCategoryName !== category.item.title.value);
        return {
          items,
        };
      });
    }
  }

  handleClick(categoryClicked) {
    console.log(categoryClicked);
    let isPresent = false;
    for (let category of this.state.items) {
      if (category.item.title.value === categoryClicked.item.title.value) {
        isPresent = true;
      }
    }
    if (isPresent) {
      this.setState((state) => {
        const items = state.items.filter((category) => categoryClicked.item.title.value !== category.item.title.value);
        return {
          items,
        };
      });
    } else {
      this.setState((state) => {
        const items = [...state.items, categoryClicked];
        return {
          items,
        };
      });
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.urlFilter !== this.state.urlFilter && this.state.urlFilter !== '' && this.state.itemdata !== undefined) {
      for (let category of this.state.itemdata.data.filters.results.items) {
        if (this.state.urlFilter === category.item.id) {
          let items = [];
          this.setState((state) => {
            items = [...state.items, category];
            return {
              urlFilter: '',
              items: items,
            };
          });
        }
      }
    }
    if (prevState.items !== this.state.items && this.state.itemdata.data !== undefined) {
      this.props.onFiltersChange({ items: this.state.items, template: this.state.itemdata.data.criteria.results.items[0].item.template.name });
    }
    if (prevProps.resetFilters !== this.props.resetFilters) {
      this.setState({
        items: [],
      });
    }
  }

  render() {
    const designVariant = typeof this.props.fields.designVariant === 'undefined' ? '' : this.props.fields.designVariant.value;

    const { error, isLoaded, itemdata } = this.state;
    if (!this.props.fields.filterOn) return '';
    if (!isLoaded)
      return (
        <div className="widget commerce widget_product_categories">
          <div className="sweet-loading" style={{ marginTop: '50px', marginBottom: '50px' }}>
            <DotLoader css={override} sizeUnit={'px'} size={50} color={'#e1191c'} loading={!this.state.isLoaded} />
          </div>
        </div>
      );
    if (error) return `Error! ${error.message}`;
    if (!itemdata || !itemdata.data) return `Empty results`;

    let categories = [];
    for (let facet of this.props.facets) {
      if (itemdata.data.criteria.results.items[0].item.template.name === 'folderProductCategory' && facet.name === 'productcategories') {
        categories = facet.values;
      }
      if (itemdata.data.criteria.results.items[0].item.template.name === 'folderProductTag' && facet.name === 'tag') {
        categories = facet.values;
      }
      if (itemdata.data.criteria.results.items[0].item.template.name === 'folderProductBrand' && facet.name === 'brands') {
        categories = facet.values;
      }
    }

    let items = [];
    let groupId = this.state.itemdata.data.criteria.results.items[0].item.id.toLowerCase();
    items.push(
      <option key={groupId} value={`${groupId}__default__default`}>
        Sélectionnez...
      </option>
    );
    for (let category of itemdata.data.filters.results.items) {
      items.push(
        <option key={category.item.id} value={`${groupId}__${category.item.id}__${category.item.title.value}`}>
          {category.item.title.value}
        </option>
      );
    }

    return (
      <div className={`widget commerce widget_product_categories ${designVariant}`}>
        <h4 className="widget-title">{this.state.itemdata.data.criteria.results.items[0].item.title.value} :</h4>
        <select data-filter-name={this.state.itemdata.data.criteria.results.items[0].item.title.value} className="product-categories-select" onChange={this.handleCategoriesChange}>
          {items}
        </select>
      </div>
    );
  }
}

export default withSitecoreContext()(FilterCategories);
