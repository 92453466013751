import React from "react";
import {
  Text,
  Image,
  withSitecoreContext,
  isExperienceEditorActive,
  RichText
} from "@sitecore-jss/sitecore-jss-react";
import StarRating from "../StarRating";
import "./carouselProduct.scss";
import { Carousel } from "react-responsive-carousel";
import Lightbox from "react-images";

import ProductReviews from "../../utils/ProductReviews/index.js";
import "./ProductSummary.scss";

import ReactComponent from "../../ReactComponent";
import { loader as gqlLoader } from "graphql.macro";
import { Query } from "react-apollo";
import {DotLoader} from "react-spinners";
import {css} from "@emotion/core";
import i18next from 'i18next';
import { handleWriteReview } from '../Tagging';

const override = css`
  margin: auto;
`;

const RatingsReviewQuery = gqlLoader('./query.graphql');

class ProductSummary extends ReactComponent {
  constructor(props) {
    super(props);
    this.onClose = this.onClose.bind(this);
    this.gotoPrevious = this.gotoPrevious.bind(this);
    this.gotoNext = this.gotoNext.bind(this);
    this.onClickThumbnail = this.onClickThumbnail.bind(this);
    this.state = {
      error: null,
      isLoaded: false,
      itemdata: [],
      photoIndex: 0,
      isOpen: false,
      showMessage: '',
    };
  }

  componentDidMount() {
    // scroll fix
    window.scrollTo(0, 0);
  }

  handleChange(event) {
    this.setState({ isOpen: true, photoIndex: event });
  }
  onClose() {
    this.setState({ isOpen: false });
  }
  gotoNext() {
    this.setState({ photoIndex: this.state.photoIndex + 1 });
  }
  gotoPrevious() {
    this.setState({ photoIndex: this.state.photoIndex - 1 });
  }

  onClickThumbnail(event) {
    this.setState({ photoIndex: event });
  }

  render() {
    const { isOpen } = this.state;

    let carouselImages = [];
    if (
      this.props.sitecoreContext.route.fields.image1 &&
      this.props.sitecoreContext.route.fields.image1.value &&
      this.props.sitecoreContext.route.fields.image1.value.src &&
      this.props.sitecoreContext.route.fields.image1.value.src.length > 0
    ) {
      carouselImages.push(
        <div key={1}>
          <img src={this.props.sitecoreContext.route.fields && this.props.sitecoreContext.route.fields.image1.value.src} alt="" />
        </div>
      );
    }
    if (
      this.props.sitecoreContext.route.fields.image2 &&
      this.props.sitecoreContext.route.fields.image2.value &&
      this.props.sitecoreContext.route.fields.image2.value.src &&
      this.props.sitecoreContext.route.fields.image2.value.src.length > 0
    ) {
      carouselImages.push(
        <div key={2}>
          <img src={this.props.sitecoreContext.route.fields && this.props.sitecoreContext.route.fields.image2.value.src} alt="" />
        </div>
      );
    }
    if (
      this.props.sitecoreContext.route.fields.image3 &&
      this.props.sitecoreContext.route.fields.image3.value &&
      this.props.sitecoreContext.route.fields.image3.value.src &&
      this.props.sitecoreContext.route.fields.image3.value.src.length > 0
    ) {
      carouselImages.push(
        <div key={3}>
          <img src={this.props.sitecoreContext.route.fields && this.props.sitecoreContext.route.fields.image3.value.src} alt="" />
        </div>
      );
    }
    if (
      this.props.sitecoreContext.route.fields.image4 &&
      this.props.sitecoreContext.route.fields.image4.value &&
      this.props.sitecoreContext.route.fields.image4.value.src &&
      this.props.sitecoreContext.route.fields.image4.value.src.length > 0
    ) {
      carouselImages.push(
        <div key={4}>
          <img src={this.props.sitecoreContext.route.fields && this.props.sitecoreContext.route.fields.image4.value.src} alt="" />
        </div>
      );
    }

    let imageArray = [];

    if (this.props.sitecoreContext.route.fields.image1.value && this.props.sitecoreContext.route.fields.image1.value.src != null) {
      imageArray.push({
        src: this.props.sitecoreContext.route.fields.image1.value.src,
      });
    }

    if (this.props.sitecoreContext.route.fields.image2.value && this.props.sitecoreContext.route.fields.image2.value.src != null) {
      imageArray.push({
        src: this.props.sitecoreContext.route.fields.image2.value.src,
      });
    }

    if (this.props.sitecoreContext.route.fields.image3.value && this.props.sitecoreContext.route.fields.image3.value.src != null) {
      imageArray.push({
        src: this.props.sitecoreContext.route.fields.image3.value.src,
      });
    }

    if (this.props.sitecoreContext.route.fields.image4.value && this.props.sitecoreContext.route.fields.image4.value.src != null) {
      imageArray.push({
        src: this.props.sitecoreContext.route.fields.image4.value.src,
      });
    }

    //
    var productId = this.props.sitecoreContext.route.itemId;
    productId = productId.split('-').join('').toLowerCase();

    return (
      <>
        <Query query={RatingsReviewQuery} variables={{ productId: productId, indexname: this.indexName }}>
          {({ loading, error, data }) => {
            if (loading)
              return (
                <div className="sweet-loading" style={{ marginTop: '50px', marginBottom: '50px' }}>
                  <DotLoader css={override} sizeUnit={'px'} size={50} color={'#e1191c'} loading={!this.state.isLoaded} />
                </div>
              );

            if (error) return <div>Error: {error.message}</div>;

            return (
              <>
                {data &&
                  data.product.results.items.map((product, keyIndex) => (
                    <React.Fragment key={keyIndex}>
                      <div className={`row summary-container single-product`}>
                        <div className="col-sm-6 entry-image">
                          <div className="single-product-images single-product__media">
                            {isExperienceEditorActive() && (
                              <div className="product-thumbnail">
                                <img
                                  src={
                                    this.props.sitecoreContext.route.fields &&
                                    this.props.sitecoreContext.route.fields.image1 &&
                                    this.props.sitecoreContext.route.fields.image1.value.src
                                  }
                                />
                                <img
                                  src={
                                    this.props.sitecoreContext.route.fields &&
                                    this.props.sitecoreContext.route.fields.image2 &&
                                    this.props.sitecoreContext.route.fields.image2.value.src
                                  }
                                />
                                <img
                                  src={
                                    this.props.sitecoreContext.route.fields &&
                                    this.props.sitecoreContext.route.fields.image3 &&
                                    this.props.sitecoreContext.route.fields.image3.value.src
                                  }
                                />
                                <img
                                  src={
                                    this.props.sitecoreContext.route.fields &&
                                    this.props.sitecoreContext.route.fields.image4 &&
                                    this.props.sitecoreContext.route.fields.image4.value.src
                                  }
                                />
                              </div>
                            )}
                            {!isExperienceEditorActive() &&
                              imageArray.length > 0 &&
                              this.props.sitecoreContext.route.fields.image1 &&
                              this.props.sitecoreContext.route.fields.image1.value &&
                              this.props.sitecoreContext.route.fields.image1.value.src !== '' && (
                                <Carousel
                                  showStatus={false}
                                  showIndicators={false}
                                  onClickItem={this.handleChange.bind(this)}
                                  showArrows={imageArray.length > 1 ? true : false}>
                                  {carouselImages}
                                </Carousel>
                              )}
                            {isOpen && (
                              <Lightbox
                                images={imageArray}
                                isOpen={this.state.isOpen}
                                onClickPrev={this.gotoPrevious}
                                onClickNext={this.gotoNext}
                                onClose={this.onClose}
                                backdropClosesModal={true}
                                currentImage={this.state.photoIndex}
                                enableKeyboardInput={true}
                                showImageCount={false}
                                showThumbnails={true}
                                onClickThumbnail={this.onClickThumbnail.bind(this)}
                              />
                            )}
                          </div>
                        </div>
                        <div className="col-sm-6 entry-summary">
                          <div className="summary">
                            <h1 className="single-product__title">
                              <Text field={this.props.sitecoreContext.route.fields && this.props.sitecoreContext.route.fields.title} />
                            </h1>
                            <h2 className="single-product__sub-title">
                              <Text field={this.props.sitecoreContext.route.fields && this.props.sitecoreContext.route.fields.beneficeProduit} />
                            </h2>

                            <div className=" single-product__rating">
                              {product.item.rating && product.item.rating.value != null && product.item.rating.value != 0 && (
                                <div className="single-product__rating__stars">
                                  <StarRating value={product.item.rating.value} />
                                  <ProductReviews productId={product.item.id} />
                                </div>
                              )}
                              <div className="single-product__rating__cta">
                                <a
                                  onClick={() => handleWriteReview(this.props.sitecoreContext.route.fields.title.value, product.item.codeEan.value)}
                                  href="#review_form_wrapper">
                                  Ajouter un commentaire
                                </a>
                              </div>
                            </div>

                            <div className="product-excerpt single-product__exerpt">
                              <RichText
                                field={this.props.sitecoreContext.route.fields && this.props.sitecoreContext.route.fields.productShortDescription}
                              />
                            </div>
                            <div className="single-product__cta">
                              <a
                                // onClick={() => handleClickToBuy(this.props.sitecoreContext.route.fields.title, product.item.codeEan.value)}
                                data-eans={product.item && product.item.codeEan.value}
                                className={
                                  this.props.sitecoreContext.route.fields.natural.value
                                    ? 'swn-tag-wtb-btn btn btn_custom_oro isGreenButton'
                                    : 'swn-tag-wtb-btn btn btn_custom_oro isRedButton'
                                }>
                                {i18next.t('FindProduct')}
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                    </React.Fragment>
                  ))}
              </>
            );
          }}
        </Query>
        {this.props.sitecoreContext.route.fields.videoID.value !== '' && (
          <div className="videoContainer">
            <iframe
              src={'https://www.youtube.com/embed/' + this.props.sitecoreContext.route.fields.videoID.value}
              description={this.props.sitecoreContext.route.fields.videoMetaDescription.value}
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen></iframe>
          </div>
        )}
      </>
    );
  }
}

export default withSitecoreContext()(ProductSummary);
