import React from "react";
import { RichText } from "@sitecore-jss/sitecore-jss-react";
import StarRating from "../StarRating";
import Lightbox from "lightbox-react";
import "lightbox-react/style.css";
import ProductReviews from "../../utils/ProductReviews/index.js";
import NavLinkAdv from "../../utils/NavLinkAdv";
import {getRelativePaths} from '../../utils/Utils.js';
import i18next from 'i18next';
import { productClickTagging, handleClickToBuy } from '../Tagging';

// PROPS :
// - product (graphql product item, required)
// - listView (boolean, optional)
class ProductGridItem extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      photoIndex: 0,
      isOpen: false,
      isModalOpen: false,
    };
    this.openLightBox = this.openLightBox.bind(this);
    this.openModal = this.openModal.bind(this);
    this.closeModal = this.closeModal.bind(this);
  }

  componentDidMount() {
    document
      .querySelector('a[data-eans="' + this.props.product.item.codeEan.value + '"]')
      .addEventListener('click', () => handleClickToBuy(this.props.product.item.title.value, this.props.product.item.codeEan.value));
  }

  openLightBox(photoIndex) {
    this.setState({
      photoIndex: photoIndex,
      isOpen: true,
    });
  }

  openModal() {
    this.setState({
      isModalOpen: true,
    });
  }

  closeModal() {
    this.setState({ isModalOpen: false });
  }

  render() {
    const product = this.props.product;
    const columnsize = this.props.columnsize;
    var images = [];
    if (product.item.image1) images.push(product.item.image1.src);
    if (product.item.image2) images.push(product.item.image2.src);
    if (product.item.image3) images.push(product.item.image3.src);
    if (product.item.image4) images.push(product.item.image4.src);

    return (
      <div
        data-gtm-name={product.item.title.value}
        data-gtm-ean={product.item.codeEan?.value}
        data-gtm-category={product.item.url.split('/')[2]}
        data-gtm-rating={product.item.rating?.numberValue}
        data-gtm-list="list-result-range"
        onClick={() =>
          productClickTagging(
            product.item.title.value,
            product.item.codeEan?.value,
            product.item.url.split('/')[2],
            product.item.rating?.numberValue,
            'list-result-range'
          )
        }
        className={product.item.natural.value === '1' ? 'product-container isHoverGreen' : 'product-container isHoverRed'}>
        <NavLinkAdv to={getRelativePaths(product.item.url)}>
          <figure>
            <div className="product-wrap">
              <div className="product-images">
                <div className="shop-loop-thumbnail shop-loop-front-thumbnail">
                  <img src={product.item.image1.src} alt={product.item.image1.alt} />
                </div>
              </div>
            </div>
            <figcaption>
              <div className="shop-loop-product-info">
                <h3 className={'product_title'}>
                  <div className="title" to={getRelativePaths(product.item.url)}>
                    {product.item.title.value}
                  </div>
                </h3>
                <p>{product.item.beneficeProduit && product.item.beneficeProduit.value}</p>
                {product.item.rating.numberValue != null && product.item.rating.numberValue != 0 && (
                  <div className="info-meta clearfix">
                    <StarRating value={product.item.rating.numberValue} />
                    <ProductReviews productId={product.item.id} />
                  </div>
                )}
                {product.item.codeEan ? (
                  <a
                    onClick={() => handleClickToBuy(product.item.title.value, product.item.codeEan.value)}
                    data-eans={product.item.codeEan.value}
                    className={
                      product.item.natural.value === '1'
                        ? 'swn-tag-wtb-btn btn btn_custom_oro isGreenButton'
                        : 'swn-tag-wtb-btn btn btn_custom_oro isRedButton'
                    }>
                    {i18next.t('Acheter')}
                  </a>
                ) : (
                  <NavLinkAdv
                    to={getRelativePaths(product.item.url)}
                    className={
                      product.item.natural.value === '1'
                        ? 'swn-tag-wtb-btn btn btn_custom_oro isGreenButton'
                        : 'swn-tag-wtb-btn btn btn_custom_oro isRedButton'
                    }>
                    {i18next.t('Acheter')}
                  </NavLinkAdv>
                )}
                {this.props.listView && (
                  <>
                    <div className="info-excerpt">{product.item.shortDescription.value}</div>
                  </>
                )}
              </div>
            </figcaption>
          </figure>
        </NavLinkAdv>
      </div>
    );
  }
}

export default ProductGridItem;
